import React, { useState, useEffect } from 'react'
import { Col, Form, Row, Spinner, Table, Button } from 'react-bootstrap'
import userService from 'services/user.service';

export const ProjectionsRelations = () => {
    const [data, setData] = useState(undefined);
    const [loanding, setLoanding] = useState(false);
    const [msg, setMsg] = useState('');

    const getCalculate12MonthProjection = async() => {
        setLoanding(true);
        const resp = await userService.getCalculate12MonthProjectionByUserId();

        setLoanding(false);
        
        if( resp.status == 1 && resp.objModel !== null && typeof resp.objModel == 'object'){
            setData(resp.objModel);
            return;
        }

        if( resp.objModel == null ){
            setMsg('No hay datos');
            return;
        }

        setMsg(resp.description);
    }

    useEffect(() => {
      getCalculate12MonthProjection();
    }, [])
    
    

    return (
        <Row className='py-5'>
            <Col xs={12}>
                <div className='mb-3 d-flex'>
                    <Button
                        size="md"
                        variant="secondary"
                        type='button'
                        onClick={getCalculate12MonthProjection}
                    >
                        Actualizar
                    </Button>
                </div>
                <Table responsive>
                    <thead className="table-head">
                        <tr>
                            <th>Total de Conciliaciones</th>
                            <th>Cantidad de Meses</th>
                            <th>Promedio</th>
                            <th>Proyeccion anual</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !loanding && data &&
                            <tr>
                                <td>{data.totalAmount}</td>
                                <td>{data.monthsCount}</td>
                                <td>{data.avgAmount.toFixed(2)}</td>
                                <td>{data.projected12Months.toFixed(2)}</td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </Col>
            <Col sm={12}>
                {loanding && <div style={{ textAlign: 'center' }}>
                    <Spinner animation="border" variant="dark">
                    </Spinner>
                    <p><Form.Label>Cargando información...</Form.Label></p>
                </div>
                }
                {!loanding && msg !== "" && <Form.Label>{msg}</Form.Label>}
            </Col>
        </Row>
    )
}
