import { useEffect, useState } from 'react';
import { Button, Alert, Card, Form, InputGroup, Row, Col, Table, Spinner } from 'react-bootstrap';
import Swal from "sweetalert2";

import userService from 'services/user.service';
import authService from 'services/auth.service';

const formatDate = (datee) => {
    let date = new Date(datee);
    let month = `${(date.getMonth() + 1)}`.padStart(2,"0");
    let day = `${date.getDate()}`.padStart(2, "0");
        date = `${date.getFullYear()}/${month}/${day}`;

        return date;
}

export const ThirdPartyPayments = () => {

    const [validated, setValidated] = useState(false);
    const [isLoadFile, setIsLoadFile] = useState(false);

    const [data, setData] = useState([]);
    const [loanding, setLoanding] = useState(false);
    const [msg, setMsg] = useState('');

    const handleSubmit = async(event) => {
        event.preventDefault();

        const form = event.currentTarget;
        const files = form.elements.validationCustomFile.files;

        if (form.checkValidity() === false || files.length == 0) {
            event.stopPropagation();
            setValidated(false);
            return
        }

        setValidated(true);
        setIsLoadFile(true);

        const file = files[0];
        const idUser = +authService.getCurrentIdUser();
        const dateCurrent = new Date(Date.now()).toISOString();


        let formData = new FormData();
        formData.append('file', file);
        formData.append('idUser', idUser);
        formData.append('createDate', dateCurrent);

        Swal.fire({
            position: "top-end",
            icon: "info",
            title: "Guardando informacion...",
            showConfirmButton: false,
            timer: 2500,
            toast: true,
        });
        

        const resp = await userService.conciliationInsertThirdPartyPayment(formData);

        setIsLoadFile(false);
        console.log(resp, 'rsp')

        if( resp.status == 1 ){
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: resp.description,
                showConfirmButton: false,
                timer: 1500,
                toast: true,
            });

            return;
        }


        Swal.fire({
            position: "top-end",
            icon: "error",
            title: resp.description,
            showConfirmButton: false,
            timer: 1500,
            toast: true,
        });
    };

    const onDownloadFile = async() => {
        Swal.fire({
            position: "center",
            icon: 'info',
            title: "Descargando documento...",
            showConfirmButton: false,
            
        });

        const resp = await userService.getGetAuthorizationLetter();

        if ( resp.objModel != null && typeof resp.objModel == 'object' ){
            const { toBase64String, fileName } = resp.objModel;

            if( fileName.includes(".docx") ){
                const link = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${toBase64String}`;
                let linkDown = document.createElement('a');
                linkDown.href = link;
                linkDown.download = fileName;
                linkDown.click();

                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Documento descargado...",
                    showConfirmButton: false,
                    timer: 1500
                });

                linkDown.remove();
            }
        }
        
        Swal.close()
    }

    const listThirdPartyPaymentGetByUserId = async() => {
        setLoanding(true);

        const resp = await userService.getThirdPartyPaymentGetByUserId();

        setLoanding(false);
        
        if( resp.status == 1 && Array.isArray(resp.objModel) ){

            if( resp.objModel.length > 0  ){
                setData(resp.objModel);
                return;
            }

            setMsg('No hay datos');
            return;
        }

        Swal.fire({
            position: "top-end",
            icon: "error",
            title: resp.description,
            showConfirmButton: false,
            timer: 1500,
            toast: true,
        });
    }

    useEffect(() => {
      listThirdPartyPaymentGetByUserId();
    }, [])
    

    return (
        <section style={{
            padding: '40px 0px'
        }}>
            <Row>
                <Col xs={12} md={4}>
                    <div className='mb-4'>
                        <Button
                            variant='danger'
                            type='button'
                            className='mb-3'
                            onClick={onDownloadFile}
                        >
                        DESCARGUE AQUÍ SU CARTA DE AUTORIZACIÓN
                        </Button>
                        <Alert variant='warning' style={{
                            maxWidth: '380px',
                            margin: '0 auto'
                        }}>
                        Descargue la carta de autorización. Una vez descargado el archivo, debe completar los datos. Por último, subir el archivo completado al sistema.
                        </Alert>
                    </div>
                    <Card style={{
                        maxWidth: '390px',
                        margin: '0 auto'
                    }}>
                        <Card.Body>
                            <Form oValidate validated={validated} onSubmit={handleSubmit}>
                                <Form.Group className="mb-4" controlId="validationCustomFile">
                                    <Form.Label>Carta de autorización*</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                            placeholder='Subir la Carta de autorización completada'
                                            type='file'
                                            name='file'
                                            accept='application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Por favor, debe subir el archivo de carta de autorización completado
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Button type='submit' disabled={isLoadFile}>
                                    Guardar
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={8}>
                    <div className='mb-3 d-flex'>
                        <Button 
                            size="md"
                            variant="secondary"
                            type='button'
                            onClick={listThirdPartyPaymentGetByUserId}
                        >
                            Actualizar
                        </Button>
                    </div>
                    <Table responsive>
                        <thead className="table-head">
                            <tr>
                                <th>Nro </th>
                                <th>Fecha de registro</th>
                                <th>Nombres y Apellidos</th>
                                <th>Usuario</th>
                                <th>Correo electronico</th>
                                <th>Nro. Teléfono</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                !loanding &&
                                data.map((item, idx) => (
                                    <tr key={idx}>
                                        <td>
                                            { idx + 1 }
                                        </td>
                                        <td>{formatDate(item.createDate)}</td>
                                        <td>{item.names}</td>
                                        <td>{item.username}</td>
                                        <td>{item.email}</td>
                                        <td>{item.telefono}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                    <Col sm={12}>
                        {loanding && <div style={{ textAlign: 'center' }}>
                            <Spinner animation="border" variant="dark">
                            </Spinner>
                            <p><Form.Label>Cargando información...</Form.Label></p>
                        </div>
                        }
                        {!loanding && msg !== "" && <Form.Label>{msg}</Form.Label>}
                    </Col>
                </Col>
            </Row>
        </section>
    )
}
